<template>
  <div class="experts-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Experts</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="520">
        <v-text-field
          class="field46 me-4 mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search expert..."
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
        <v-btn @click="modal = true" depressed width="136" large class="primary mt-sm-0 mt-2">Add new</v-btn>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openLink(item.id)">
                <td>{{ item.first_name }} {{ item.last_name }}</td>
                <td>{{ item.username }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.rating }} / 5</td>
                <td>{{ item.reviews_count }}</td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openLink(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Name</div>
              <div class="font-weight-medium f18">{{ item.first_name }} {{ item.last_name }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Username</div>
              <div class="font-weight-medium f18">{{ item.username }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Email</div>
              <div class="font-weight-medium f18 text-capitalize">{{ item.email }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Rating</div>
              <div class="font-weight-medium f18">{{ item.rating }} / 5</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Rewiews</div>
              <div class="font-weight-medium f18">{{ item.reviews_count }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-h6 font-weight-bold mb-3">Invite Expert</div>
        <div class="d-flex flex-md-row flex-column">
          <v-text-field
            class="field46 me-md-3"
            v-model="dataModal.first_name"
            placeholder="First name"
            :error-messages="firstNameErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 mt-md-0 mt-n2 ms-md-3"
            v-model="dataModal.last_name"
            placeholder="Last name"
            :error-messages="lastNameErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <v-text-field
          class="field46 mt-n2"
          v-model="dataModal.username"
          placeholder="Username"
          :error-messages="userNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="dataModal.email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-autocomplete
          class="field46 mt-n2"
          v-model="dataModal.country"
          @change="getCity"
          placeholder="Country"
          :items="countryList"
          item-text="name"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <v-autocomplete
          class="field46 mt-n2"
          v-model="dataModal.city"
          placeholder="City"
          :error-messages="cityErrors"
          :items="cityList"
          item-text="name"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
          :disabled="!dataModal.country"
        ></v-autocomplete>
        <div class="text-end mt-2">
          <v-btn @click="modal = false" width="100" large text color="primary">Cancel</v-btn>
          <v-btn @click="create" class="ms-3" depressed width="176" large color="primary">Invite</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      search: this.$route.query.src || '',
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Name', value: 'first_name', sortable: false },
        { text: 'Username', value: 'username', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Rating', value: 'rating', sortable: false },
        { text: 'Rewiews', value: 'reviews_count', sortable: false },
      ],
      dataModal: {},
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getCountryList');
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async getCity() {
      await this.$store.dispatch('getCityList', this.dataModal.country);
    },
    openLink(id) {
      this.$router.push(`/experts/${id}/edit-profile`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getExpertsList', {
          search: this.search,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async create() {
      this.error = [];
      await this.$store.dispatch('createExpetr', this.dataModal).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.dataModal = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.expertsList;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter last name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    userNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'username__required') && errors.push('Please enter username');
      this.error.find((item) => item == 'username__invalid') && errors.push('Provided username is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
    cityErrors() {
      const errors = [];
      this.error.find((item) => item == 'city__required') && errors.push('Please enter city');
      this.error.find((item) => item == 'city__invalid') && errors.push('Provided city is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setExpertsList');
    this.$store.dispatch('setCountryList', []);
    this.$store.dispatch('setCityList');
  },
};
</script>

<style lang="scss">
.experts-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
